<template>
  <div class="columns text-left">
    <div
      class="column"
      v-if="(financiadas && financiadas.length > 0) || esFinanciada"
    >
      <p v-if="esFinanciada" class="font-bold text-2xl">Disposición raíz</p>
      <cuenta-financiada-card
        v-if="esFinanciada"
        :tipo_principal="tipoDeOrigen"
        :id_principal="origen"
        :tipo_secundario="tipoDeOrigen"
        :id_secundario="origen"
        :origenId="origenIdToShow"
      />
      <div v-if="!esFinanciada">
        <p class="font-bold text-2xl">
          Cuentas financiadas
        </p>
        <div v-for="financiada in financiadas" :key="financiada.id">
          <cuenta-financiada-card
            tipo_principal="Cuenta"
            :id_principal="financiada.cuenta.id"
            tipo_secundario="Disposición"
            :id_secundario="financiada.id"
          />
        </div>
      </div>
    </div>
    <div
      class="mt-32 m-auto w-full"
      v-else
    >
      <empty-state
        type="svg"
        imagen="/images/cuentas_cp.svg"
        text-class="items-baseline"
        :titulo="'No hay cuentas financiadas'"
        subtitulo="Puedes crear una en la pestaña de cuentas."
      />
    </div>
  </div>
</template>

<script>
import CuentaFinanciadaCard from "@/components/cards/CuentaFinanciadaCard.vue";
import EmptyState from "@/components/generals/EmptyState";

export default {
  name: "cuentasFinanciadas",
  components: {
    CuentaFinanciadaCard,
    EmptyState
  },
  props: {
    tipoDeOrigen: String,
    origen: null,
    origenIdToShow: null,
    financiadas: Array,
    esFinanciada: Boolean
  }
};
</script>
