<template>
  <div v-if="getDocumentos != null || getDocumentosLinea != null">
    <documentos
      :pasiva="pasiva"
      :arrendamiento="arrendamiento"
      :documentos="documentos"
      @desligar="desligarDocumento"
      @togglecf="toggleCapvitalFiles"
      :tipo="tipo"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Documentos from "@/components/generals/Documentos";

export default {
  name: "Documentacion",
  components: {
    Documentos
  },
  data() {
    return {
      file: [],
      docs: []
    };
  },
  props: {
    arrendamiento: {
      type: Boolean,
      default: false
    },
    pasiva: {
      type: Boolean,
      default: false
    },
    tipo: {
      type: String,
      default: "disposicion"
    }
  },
  methods: {
    // Activa Capvital Files con la información necesaria del objeto a agregar documentos
    toggleCapvitalFiles() {
      let id;
      let persona;
      let grupo = null;

      if (this.tipo == "disposicion") {
        id = this.$route.params.idDisposicion;
        persona = this.getDisposicionDetalle.acreditado
      }
      
      if (this.tipo == "linea") {
        id = this.$route.params.idLinea;
        persona = this.getLinea.relaciones.find(x => x.rol.nombre == "Cliente")?.relacionado

        if (this.getLinea.grupo_credito_id) {
          grupo = this.getLinea.grupo_credito;
        }
      }

      const data = {
          id,
          persona,
          grupo,
          type: this.tipo,
          status: true,
      };

      this.$store.dispatch("files/toggleCapvitalFiles", data);
    },
    /*
      - @id (Integer): Id del documento a desligar
    */
    desligarDocumento(id) {
      if (this.tipo == "disposicion") {
        let data = {
          file_id: id,
          disposicion_id: this.$route.params.idDisposicion
        };
        this.$store.dispatch("disposicion/desligarDocumentos", data);
      } else {
        let data = {
          file_id: id,
          linea_id: this.$route.params.idLinea
        };
        this.$store.dispatch("lineascredito/desligarDocumentos", data);
      }
    }
  },
  computed: {
    ...mapGetters(["getMe"]),
    ...mapGetters("disposicion", ["getDocumentos", "getDisposicionDetalle"]),
    ...mapGetters("lineascredito", ["getLinea", "getDocumentosLinea"]),
    documentos() {
      if (this.tipo == "disposicion") {
        return this.getDocumentos;
      } else {
        return this.getDocumentosLinea;
      }
    }
  },
  created() {
    if (this.tipo == "disposicion") {
      this.$store.dispatch(
        "disposicion/getDocumentos",
        this.$route.params.idDisposicion
      );
      this.$store.dispatch(
        "disposicion/getDisposicionDetalle",
        this.$route.params.idDisposicion
      );
    }
    if (this.tipo == "linea") {
      this.$store.dispatch(
        "lineascredito/getDocumentos",
        this.$route.params.idLinea
      );
      this.$store.dispatch(
        "lineascredito/getLineaById",
        this.$route.params.idLinea
      );
    }
  }
};
</script>
