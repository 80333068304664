<template>
  <div class="sidebar__wrapper">
    <div
      class="has-border-radius h-full flex items-center justify-center flex-col p-4"
      :class="{
        'has-background-primary-04': !pasiva,
        'has-background-pasivo-04': pasiva
      }"
      v-if="!toggleNew && !toggleExists"
    >
      <empty-state
        class="hidden md:block"
        type="svg"
        :vertical="true"
        imagen="/images/ligar_garantias.svg"
        imgclass="w-4/5 mb-4"
        titulo="Liga garantías"
        :subtitulo="'A esta ' + tipo"
      />
      <b-dropdown
        aria-role="list"
        class="w-full"
        expanded
        position="is-top-right"
      >
        <b-button
          :type="pasiva ? 'is-pasivo' : 'is-primary'"
          slot="trigger"
          icon-left="plus"
          expanded
        >
          Ligar garantía
        </b-button>
        <b-dropdown-item aria-role="listitem" @click="() => (toggleNew = true)"
          >Nueva garantía</b-dropdown-item
        >
        <b-dropdown-item
          aria-role="listitem"
          @click="() => (toggleExists = true)"
          >Garantía existente</b-dropdown-item
        >
      </b-dropdown>
    </div>
    <div v-if="toggleNew || toggleExists" class="p-4">
      <div class="columns text-left">
        <div class="column">
          <p class="font-bold text-2xl">Ligar una garantía</p>
        </div>
        <div class="column is-1 flex items-center">
          <b-icon
            icon="times"
            class="cursor-pointer"
            @click.native="
              () => {
                toggleNew = false;
                toggleExists = false;
              }
            "
          />
        </div>
      </div>
      <validation-observer
        v-if="toggleExists"
        ref="observer"
        v-slot="{ validate }"
        class="w-full text-left"
      >
        <valid-auto-complete
          rules="required"
          label="Buscar una garantía"
          placeholder="Buscar..."
          v-model="garantiaSearch"
          :data="getGarantias"
          show="descripcion"
          class="text-left"
          @typing="buscarGarantias"
          @select="setGarantiaExistente"
          :expanded="true"
        />
        <b-button
          class="mt-4"
          :type="pasiva ? 'is-pasivo' : 'is-primary'"
          icon-left="plus"
          expanded
          @click="validate().then(result => ligarGarantiaExistente(result))"
          >Ligar garantia</b-button
        >
      </validation-observer>
      <validation-observer
        v-if="toggleNew"
        ref="observer"
        v-slot="{ validate }"
        class="w-full text-left"
      >
        <valid-input
          rules="required"
          label="Descripción"
          placeholder="Introduzca una descripción..."
          v-model="form.descripcion"
          type="textarea"
          :expanded="true"
        />
        <valid-select
          rules="required"
          label="Tipo"
          placeholder="Selecciona uno..."
          v-model="form.tipo"
          fieldClass="has-text-grey-darker"
          :data="tiposGarantia"
          grouped
          @input="getAtributos"
          show="nombre"
          :expanded="true"
          realValue="id"
        />
        <valid-currency-input
          :rules="`${[4, 17].includes(form.tipo) ? '' : 'notcero|'}currency`"
          label="Valor"
          placeholder="0.00"
          v-model="form.valor"
          :expanded="true"
          addon
          addonIcon="dollar-sign"
        />
        <valid-select
          rules="required"
          label="Moneda"
          placeholder="Selecciona uno..."
          v-model="form.moneda"
          fieldClass="has-text-grey-darker"
          :data="getMonedas"
          show="nombre"
          :expanded="true"
          realValue="id"
        />
        <valid-input
          rules="required"
          label="Identificador de la garantía"
          placeholder="Introduzca un identificador..."
          v-model="form.identificador"
          type="text"
          :expanded="true"
        />
        <div
          v-if="form.atributos.length > 0"
          class="mt-2 has-background-primary-16 has-border-radius p-3"
        >
          <p class="font-bold text-lg">Atributos</p>
          <div v-for="atributo in form.atributos" :key="atributo.id">
            <valid-input
              rules="required"
              :label="atributo.atributo.nombre"
              v-if="atributo.atributo.tipo_dato_id == 1"
              placeholder="Introduzca el número..."
              v-model="atributo.value"
              type="number"
              :expanded="true"
            />
            <ValidInput
              addon
              addonIcon="percentage"
              rules="required|min_value:0.00"
              v-if="
                [
                  'Cobertura efectiva',
                  'Costo Prima del fondeador',
                  'Costo Prima al acreditado'
                ].includes(atributo.atributo.nombre)
              "
              :label="atributo.atributo.nombre"
              placeholder="Introduzca el porcentaje..."
              v-model="atributo.value"
              :expanded="true"
            />
            <valid-currency-input
              rules="required"
              v-if="
                atributo.atributo.tipo_dato_id == 2 &&
                  ![
                    'Cobertura efectiva',
                    'Costo Prima del fondeador',
                    'Costo Prima al acreditado'
                  ].includes(atributo.atributo.nombre)
              "
              :label="atributo.atributo.nombre"
              placeholder="Introduzca el monto..."
              v-model="atributo.value"
            />
            <valid-input
              rules="required"
              :label="atributo.atributo.nombre"
              v-if="atributo.atributo.tipo_dato_id == 3"
              placeholder="Introduzca la información..."
              v-model="atributo.value"
              type="text"
              :expanded="true"
            />
            <valid-datepicker
              rules="required"
              :label="atributo.atributo.nombre"
              v-if="
                atributo.atributo.tipo_dato_id == 4 ||
                  atributo.atributo.tipo_dato_id == 5
              "
              placeholder="Introduzca la información..."
              v-model="atributo.value"
              type="text"
              :expanded="true"
            />
            <b-checkbox
              class="has-text-grey-darker mt-2"
              v-model="atributo.value"
              v-if="atributo.atributo.tipo_dato_id == 6"
              >{{ atributo.atributo.nombre }}</b-checkbox
            >
          </div>
        </div>
        <documentos
          :documentos="form.files"
          :tipo="'garantias_' + tipo"
          @desligar="desligarDocumento"
          @togglecf="toggleCapvitalFiles"
          :outlined-btn="true"
          :small="true"
        />
        <b-button
          class="mt-4"
          :type="pasiva ? 'is-pasivo' : 'is-primary'"
          icon-left="plus"
          expanded
          @click="validate().then(result => ligarGarantia(result))"
          >Ligar garantia</b-button
        >
      </validation-observer>
    </div>
  </div>
</template>

<script>
import EmptyState from "@/components/generals/EmptyState";
import { ValidationObserver } from "vee-validate";
import ValidInput from "@/components/form/ValidInput";
import ValidSelect from "@/components/form/ValidSelect";
import ValidCurrencyInput from "@/components/form/ValidCurrencyInput";
import ValidDatepicker from "@/components/form/ValidDatepicker";
import ValidAutoComplete from "@/components/form/ValidAutoComplete";
import Documentos from "@/components/generals/Documentos";
import { mapGetters } from "vuex";
import { debounce } from "@/vendors/helpers";

export default {
  name: "garantiaSidebar",
  components: {
    EmptyState,
    ValidationObserver,
    ValidInput,
    ValidCurrencyInput,
    ValidSelect,
    ValidDatepicker,
    Documentos,
    ValidAutoComplete
  },
  props: {
    tipo: String,
    archivos: [Array, null],
    pasiva: Boolean,
    acreditado: Number,
    grupo_credito_id: Number,
  },
  data() {
    return {
      toggleNew: false,
      toggleExists: false,
      garantiaSearch: "",
      garantiaSelected: null,
      form: {
        files: [],
        descripcion: null,
        tipo: null,
        valor: null,
        moneda: null,
        identificador: null,
        atributos: []
      }
    };
  },
  watch: {
    archivos: function(data) {
      this.form.files = data;
    }
  },
  computed: {
    ...mapGetters("catalogos", [
      "getMonedas",
      "getTiposGarantia",
      "getAtributosTipoGarantia"
    ]),
    ...mapGetters("disposicion", ["getArchivosGarantia"]),
    ...mapGetters("garantias", ["getGarantias"]),
    tiposGarantia() {
      if (this.getTiposGarantia != null) {
        let parents = this.getTiposGarantia.filter(
          x => x.parent_tipos_garantia_id == null
        );
        let groups = [];
        parents.forEach(parent => {
          groups.push({
            group: parent.nombre,
            children: this.getTiposGarantia.filter(
              x => x.parent_tipos_garantia_id == parent.id
            )
          });
        });
        return groups;
      }
      return [];
    }
  },
  methods: {
    // Activa Capvital Files con la información necesaria del objeto a agregar documentos
    toggleCapvitalFiles() {
      this.$emit("toggle-cf", {
        status: true
      });
    },
    /*
      - @doc (Integer): Id del documento a desligar
    */
    desligarDocumento(doc) {
      this.form.files = this.form.files.filter(function(e) {
        return e.id !== doc;
      });
    },
    /*
      Se liga la garantía a la disposición
      @result (Boolean): Resultado de las validaciones por el validationObserver de los inputs
    */
    ligarGarantia(result) {
      if (!result) {
        return;
      }
      let form = JSON.parse(JSON.stringify(this.form));
      form.files = this.form.files.map(file => file.id);
      let atributos = [];
      form.atributos.forEach((attr, index, array) => {
        if (attr.value == null) {
          array[index].value = 0;
        }
        if (attr.atributo.tipo_dato_id == 4) {
          array[index].value = this.$moment(attr.value).format("YYYY-MM-DD");
        }
        if (attr.atributo.tipo_dato_id == 2) {
          array[index].value = attr.value;
        }
        atributos.push({
          id: array[index].atributo.id,
          valor: array[index].value
        });
      });
      form.atributos = atributos;
      this.$emit("asignar-garantia", form);
      this.toggleNew = false;
      this.form = {
        descripcion: null,
        tipo: null,
        valor: null,
        moneda: null,
        identificador: null,
        atributos: [],
        files: []
      };
    },
    ligarGarantiaExistente(res) {
      if (!res) return;
      this.$emit("asignar-garantia-existente", this.garantiaSelected);
    },
    getAtributos() {
      this.form.atributos = [];
      this.$store
        .dispatch("catalogos/getAtributosTipoGarantia", this.form.tipo)
        .then(data => {
          data.forEach(atributo => {
            this.form.atributos.push({
              atributo,
              value: null
            });
          });
        });
    },
    buscarGarantias: debounce(function(search) {
      if (!search.length) {
        return;
      }
      const params = { search };

      if (this.acreditado) {
        params.user_id = this.acreditado;
      }

      if (this.grupo_credito_id) {
        params.grupo_credito_id = this.grupo_credito_id;
      }
      if(this.tipo == 'disposicion'){
        params.fondeador = true;
      }

      this.$store.dispatch("garantias/buscar", params);
    }, 400),
    setGarantiaExistente(garantia) {
      this.garantiaSelected = garantia.garantias_id || garantia.id;
    }
  },
  mounted() {
    if (this.getMonedas.length == 0) {
      this.$store.dispatch("catalogos/getMonedas");
    }
    if (this.getTiposGarantia == null) {
      this.$store.dispatch("catalogos/getTiposGarantia");
    }
  }
};
</script>

<style>
.sidebar__wrapper {
  height: 600px;
  min-height: 600px;
}
</style>
