<template>
  <div
    class="flex justify-between items-center has-background-grey-lighter card shadow-none has-border-radius my-8 px-8 py-3"
  >
    <p class="text-lg font-semibold underline cursor-pointer">
      <preview :id="id_principal" :tipo="preview_principal">
        {{ tipo_principal }} {{ origenId || id_principal }}
      </preview>
    </p>
    <div>
      <p class="font-light text-lg">{{ tipo_secundario }}</p>
      <p class="text-lg text-right underline cursor-pointer">
        <preview :id="id_secundario" :tipo="preview_secundario">
          #{{ origenId || id_secundario }}
        </preview>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "CuentaFinanciadaCard",
  props: {
    origenId: null,
    tipo_principal: String,
    tipo_secundario: String,
    id_principal: String,
    id_secundario: String
  },
  computed: {
    preview_principal() {
      switch (this.tipo_principal) {
        case "Disposición":
          return "credito";
        case "Cuenta":
          return "cuentaCP";
        case "Línea":
          return "linea";
        default:
          return null;
      }
    },
    preview_secundario() {
      switch (this.tipo_secundario) {
        case "Disposición":
          return "credito";
        case "Cuenta":
          return "cuentaCP";
        case "Línea":
          return "linea";
        default:
          return null;
      }
    }
  }
};
</script>
