<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ validate }"
    class="w-full text-left"
  >
    <div class="modal-card">
      <section class="modal-card-head pb-0">
        <p class="font-bold text-2xl">Imprimir estado de cuenta</p>
        <button
          type="button"
          class="delete absolute right-0 mr-5"
          @click="$parent.close()"
        />
      </section>
      <section class="modal-card-body has-background-white text-left">
        <div class="columns">
          <div class="column">
            <div class="mt-3">
              <valid-datepicker
                label-class="font-light has-text-grey-light"
                label="Fecha de inicio"
                rules="required"
                placeholder="seleccionar día..."
                v-model="minDate"
                :min-date="fechaMinimaEdo"
                position="is-bottom-left"
              />
            </div>
          </div>
          <div class="column">
            <div class="mt-3">
              <valid-datepicker
                label-class="font-light has-text-grey-light"
                label="Fecha final"
                rules="required"
                placeholder="seleccionar día..."
                v-model="maxDate"
                :min-date="
                  $moment(fechaMinimaEdo)
                    .add(1, 'days')
                    .toDate()
                "
                position="is-bottom-left"
              />
            </div>
          </div>
        </div>
      </section>
      <section class="modal-card-foot justify-end">
        <b-button
          type="is-primary"
          @click="validate().then(result => printEstadoCuenta(result))"
        >
          Imprimir
        </b-button>
      </section>
    </div>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import ValidDatepicker from "@/components/form/ValidDatepicker";

export default {
  name: "ModalEstadoCuenta",
  props: {
    lineaId: Number,
    disposicionId: Number,
    acreditadoId: Number,
    fechaMinima: String
  },
  data() {
    return {
      dates: [this.$fecha_cierre, this.$fecha_cierre],
      minDate: null,
      maxDate: null,
    };
  },
  components: {
    ValidDatepicker,
    ValidationObserver
  },
  computed: {
    fechaMinimaEdo() {
      return this.$moment(this.fechaMinima).toDate();
    }
  },
  methods: {
    /*
      Manda llamar a la webapp1 para imprimir el estado de cuenta
      @result (Boolean): Resultado de las validaciones
    */
    printEstadoCuenta(res) {
      if (!res) {
        return;
      }

      const store = this.lineaId ? "lineascredito" : "disposicion";

      this.$store.dispatch(`${store}/printEstadoCuenta`, {
        fecha_inicial: this.$moment(this.minDate).format("YYYY-MM-DD"),
        fecha_final: this.$moment(this.maxDate).format("YYYY-MM-DD"),
        disposicion_id: this.disposicionId,
        linea_id: this.lineaId,
      });
    },
    // Custom formatter cuando el datepicker es de tipo rango
    rangeFormatter(date) {
      return (
        "" +
        this.$moment(date[0]).format("DD/MM/YYYY") +
        " - " +
        this.$moment(date[1]).format("DD/MM/YYYY")
      );
    }
  }
};
</script>
